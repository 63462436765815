import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navigation from "../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ReactGA from "react-ga";
import Twitter from "../images/icons/twitter.png";
import YouTube from "../images/icons/youtube.png";
import LinkedIn from "../images/icons/linkedin.png";
import Github from "../images/icons/github-logo.png";
import HeadShot from "../images/head_shot_3.jpg";
import Button from "react-bootstrap/Button";
import BlogHero from "../components/blog-hero";

// Icon Pack: https://www.flaticon.com/packs/social-media-icons
// Icon Pack: https://www.flaticon.com/free-icon/github-logo_25231

class IndexPage extends React.Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
		// window.addEventListener('scroll', this.handleScroll);
	}

	handleClick = (event) => {

		ReactGA.event({
			category: 'Buttons',
			action: 'Click',
			label: event.target.id
		});

	}

	handleChange = (event) => {

	}

	handleScroll = (event) => {
		// let scrollTop = event.srcElement.body.scrollTop,
		// 	itemTranslate = Math.min(0, scrollTop/3 - 60);

		// this.setState({
		//   transform: itemTranslate
		// });

	}

	render() {
		return (
			<Layout>
				<SEO
					title="Mentorship Program"
					keywords={[
					]}
				/>
				{/* <Navigation /> */}

				<a href="#pricing">
					<Button style={{ borderRadius: 0 }} id="apply-now-top" variant="primary" size={'lg'} onClick={this.handleClick} block>Apply Now. (Limited Seats / Month)</Button>
				</a>

				<div style={{ marginTop: 20 }} />

				<Container style={{ maxWidth: 650 }}>

					<Row>
						<Col>
							<h1>Quinston's Mentorship Program</h1>
						</Col>
					</Row>

					<Row>
						<Col>

							<h3 id="doyouneedhelpinyourjourneyofbecomingaworldclassdeveloper">Do you need help in your journey of becoming a <nobr>World-Class</nobr> Developer?</h3>

							<p>Hey there,</p>

							<p>My name is Quinston.</p>

							<p>If you're here expecting to be tutored into learning a Programming Language, you're at the wrong place. I am not going to teach you how to write syntax. I am offering you something much more valuable. Something, that you might keep with you for the rest of your life.</p>

							<p>I have been writing code and building products for a long time now. It's been almost 8 years since I wrote my first line of C++ code. Over the years, I have made so many mistakes. Mistakes that have cost me dearly in terms of the most valuable currency we have. Time. </p>

							<p>I have taken so many courses where I was taught how to write code. The issue was that no one seemed to tell me what I'd do with it? Why do I have to learn so many languages? And what do I do after I learn the language?</p>

							<p>No matter how much people tell you… Programming is a creative endevour. You will never become good at Programming by reading theory. It's just never going to happen.</p>

							<p>So, how do you become good?</p>

							<p>Yes, of course. Practice is the activity that comes to mind. </p>

							<p>They say <em>Practice makes a Man / Woman Perfect</em>. Sure. But, that's not enough.</p>

							<p>But, why not?</p>

							<p>What if you practice the wrong skills? It might happen. What if you spend all your time doing things that don't generate any output? If you want to learn how to be a world-class bowler, you can't bowl against a wall forever. At some point, you're going to have to practice with a real Batsman. </p>

							<p>The concept is very simple. </p>

							<h3 id="perfectpracticemakesamanwomanperfectandyoucantdoperfectpracticewithoutaplan">Perfect Practice makes a Man / Woman Perfect and you can't do perfect practice without a plan.</h3>

							<p>So, if you are highly-motivated, driven and want to create a strong execution plan for fulfilling your goals, you're at the right place.</p>

							<p id="myjobasamentorandacoachistoansweryourquestionscreateanexecutionplanandgiveyoualltheknowledgethatihavegainedovertheyearsaboutsoftwareingproductdevelopmentworkphilosophyandhiringplacements">My job, as a mentor and a coach, is to answer your questions, create an execution plan and give you all the knowledge that I have gained over the years about Software, Product Development, Work Philosophy and Hiring (Placements).</p>

							<p>Let's work together and help you become world-class.</p>

							<div style={{ marginTop: 100 }}></div>

						</Col>

					</Row>

				</Container>

				<Container id="pricing">

					<Row>
						<Col xs={12} md={12} lg={6}>
							<div className="card-2" style={{ margin: 20, padding: 20, border: '1px solid #eeeeee' }}>
								<h3>One-to-One Mentorship for a Month</h3>
								<hr></hr>
								<ul>
									<li><b style={{ textDecoration: 'underline' }}>Only 3 Students / Month.</b></li>
									<li>Four In-Depth Sessions Per Month over Skype etc. (One Session Per Week).</li>
									<li>Unlimited Questions (over Email).</li>
									<li>Highly-detailed Execution Plan on how to fulfil your Goals.</li>
									<li><b>$300 / month.</b></li>
								</ul>
								<p style={{ textAlign: 'center' }}>︎⬇︎ Click below to apply. ⬇︎</p>
								<a href="https://docs.google.com/forms/d/e/1FAIpQLSdwE1xkbeMNncxUoHzup4sUo9gODLwHH64JboNe0CF-6x6ArA/viewform" target="_blank">
									<Button id="mentored-multiple-sessions" variant="primary" block>I want to be mentored.</Button>
								</a>
							</div>
						</Col>
						<Col xs={12} md={12} lg={6}>
							<div className="card-2" style={{ margin: 20, padding: 20, border: '1px solid #eeeeee' }}>
								<h3>One-to-One Mentorship Single Session</h3>
								<hr></hr>
								<ul>
									<li><b style={{ textDecoration: 'underline' }}>Only 5 Students / Month.</b></li>
									<li>One In-Depth Session over Skype etc.</li>
									<li>Unlimited Questions (over Email).</li>
									<li>Detailed Execution Plan on how to fulfil your Goals.</li>
									<li><b>$150 / month.</b></li>
								</ul>
								<p style={{ textAlign: 'center' }}>︎⬇︎ Click below to apply. ⬇︎</p>
								<a href="https://docs.google.com/forms/d/e/1FAIpQLSdwE1xkbeMNncxUoHzup4sUo9gODLwHH64JboNe0CF-6x6ArA/viewform" target="_blank">
									<Button id="mentored-single-session" variant="primary" block>I want to be mentored.</Button>
								</a>
							</div>
						</Col>
					</Row>

				</Container>

				<div style={{ marginTop: 100 }}></div>

				<Container style={{ maxWidth: 650, textAlign: 'center' }}>

					<Row>
						<Col>

							<h2 id="faqfrequentlyaskedquestions">
								<a href="https://www.trustpilot.com/review/quinston.com" target="_blank" style={{textDecoration: 'underline'}}>
									Testimonials from TrustPilot ➚
								</a>
							</h2>


							<p>I've always wanted to learn how to code but there's just so much that it's overwhelming. The way Quinston guided me through the entire process, was much more valuable than sitting down and just randomly mugging up some syntax. It made me understand concepts better to enable me to apply what l've learned in a muchhh better way!</p>
							<h3 style={{marginBottom: 0}}><a href="https://www.instagram.com/vikrantdbz/" target="_blank"  style={{textDecoration: ''}}>Vikrant Joshi  ➚</a></h3>
							<p><i>College Student</i></p>

						</Col>
					</Row>

				</Container >

				<div style={{ marginTop: 100 }}></div>

				<Container style={{ maxWidth: 650 }}>

					<Row>
						<Col>

							<h2 id="faqfrequentlyaskedquestions">F. A. Q. (Frequently Asked Questions)</h2>

							<h3 id="whyisthissoexpensive">Why is this so expensive?</h3>

							<p>It's actually not. Imagine this, what if you don't take the advice of an Experienced Professional and let's say you enroll in a course where you pay $2500 at a coding bootcamp. At the end of it, all you have learned is how to write a few lines of code (which you can - frankly - learn from YouTube for free). But, what if you spend a significanly less amount of money and instead, learn where to find those same resources (maybe even better) and learn them for free? </p>

							<p>There is a saying… Give a Man a Fish, and You Feed Him for a Day. Teach a Man To Fish, and You Feed Him for a Lifetime. </p>

							<p><b>I am here to teach you how to fish. Simple as that.</b></p>

							<hr></hr>

							<p></p>

							<p>If you have any further questions, shoot me an email on <b>mail@quinston.com</b></p>

							<p>Have a good one!</p>

						</Col>
					</Row>

				</Container >

				<div style={{ marginTop: 100 }}></div>

				<a href="#pricing">
					<Button style={{ borderRadius: 0 }} id="apply-now-bottom" variant="primary" size={'lg'} onClick={this.handleClick} block>Apply Now. (Limited Seats / Month)</Button>
				</a>

			</Layout >
		);
	}
}

export default IndexPage;
